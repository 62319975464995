<template>

<component :is="hospital === undefined ? 'div' : 'b-card'">
   
   <b-tabs v-if="hospital" pills>
     <!-- Tab: Account -->
     <b-tab active>
       <template #title>
         <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
         <span class="d-none d-sm-inline">{{$t('MainData')}}</span>
       </template>

  <!-- Media -->
  <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="baseURL + hospital.ImagePath"
          size="90px"
          rounded
        />
      </template>
     
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.uploadImgInput.click()"
        >
        <input
          type="file"
          class="hidden"
          ref="uploadImgInput"
          @change="updateCurrImg"
          accept="image/*"
          @on-success="successUpload"
        />
          <span class="d-none d-sm-inline">{{$t('Update')}}</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
          @click="(hospital.ImagePath= null)"
        >
          <span class="d-none d-sm-inline">{{$t('Remove')}}</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Username -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('Hospitalname')"
            label-for="Hospitalname"
          >
            <b-form-input
              id="Hospitalname"
              v-model="hospital.NameEN"
            />
          </b-form-group>
        </b-col>

        <!-- Field: HospitalGroup -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('HospitalGroup')"
            label-for="HospitalGroup"
          >
          <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="Name"
              label="Name"
              :placeholder="$t('HospitalGroup')"
              :icon-after="true"
              :options="hospitalGroups"
              v-model="hospital.HospitalGroupID"
              :reduce="(ID) => ID.ID"
            />
          </b-form-group>
        </b-col>

       
  <!-- Field: HospitalInfoEN -->
  <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('HospitalInfoEN')+'*'"
            label-for="HospitalInfoEN"
          >
          <quill-editor v-model="hospital.HospitalInfoEN" :options="editorOption" />

          </b-form-group>
        </b-col>
        <!-- Field: HospitalInfoEN -->
  <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('HospitalInfoAR')+'*'"
            label-for="HospitalInfoAR"
          >
          <quill-editor v-model="hospital.HospitalInfoAR" :options="editorOption" />

          </b-form-group>
        </b-col>
      
        <!-- HospitalRooms -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('HospitalRoomsEN')"
            label-for="HospitalRoomsEN"
          >
            <b-form-input
              id="HospitalRoomsEN"
              v-model="hospital.RoomDescriptionEN"
            />
          </b-form-group>
        </b-col>
           <!-- HospitalRoomsAR -->
           <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('HospitalRoomsAR')"
            label-for="HospitalRoomsAR"
          >
            <b-form-input
              id="HospitalRoomsAR"
              v-model="hospital.RoomDescriptionAR"
            />
          </b-form-group>
        </b-col>
      
        <!-- phonenumber -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('PhoneNumber')"
            label-for="PhoneNumber"
          >
          <VuePhoneNumberInput :placeholder="$t('PhoneNumber')" :default-country-code="defaultPhoneData.countryCode" @update="acceptNumber" v-model="hospital.Phone" />

          </b-form-group>
        </b-col>
        
          <!-- Field: ContactPersonName -->
          <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('ContactPerson')"
            label-for="ContactPerson"
          >
            <b-form-input
              id="ContactPerson"
              v-model="hospital.ContactPersonName"
            />
          </b-form-group>
        </b-col>

        <!-- Field: ContactPersonEmail -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('ContactPersonEmail')+'*'"
            label-for="ContactPersonEmail"
          >
            <b-form-input
              id="ContactPersonEmail"
              v-model="hospital.ContactPersonEmail"
            />
          </b-form-group>
        </b-col>

         <!-- Field: Address -->
         <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Address')+'*'"
            label-for="Address"
          >
            <b-form-input
              id="Address"
              v-model="hospital.Address"
            />
          </b-form-group>
        </b-col>
  
        <!-- Field: Country -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Country')"
            label-for="Country"
          >
          <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="Country"
              :placeholder="$t('Country')"
              :icon-after="true"
              label="Name"
              :options="countries"
              :reduce="(ID) => ID.ID"
              v-model="hospital.CountryID"
              @input="getRegionByCountryId(hospital.CountryID)"
            />
          </b-form-group>
        </b-col>

         <!-- Field: Provinance -->
         <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Provinance')"
            label-for="Provinance"
          >
          <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="Region"
              :placeholder="$t('Provinance')"
              :icon-after="true"
              label="Name"
              :options="regions"
              :reduce="(ID) => ID.ID"
              v-model="hospital.RegionID"
              @input="getCityByRegionId(hospital.RegionID)"
            />
          </b-form-group>
        </b-col>

         <!-- Field: City -->
         <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('City')"
            label-for="City"
          >
          <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="City"
              :placeholder="$t('City')"
              :icon-after="true"
              label="Name"
              :options="cities"
              :reduce="(ID) => ID.ID"
              v-model="hospital.CityID"
            />
          </b-form-group>
        </b-col>
<b-col  cols="12"
          md="12">
  <b-form-group
  :label="$t('Forinpatientsanaccompanyingpersonisallowedtostaywiththepatientintheroom')"
            label-for="Accompanyingperson"
        >
          <b-form-checkbox
          v-model="hospital.IsAllowAccompanyingperson"
            name="Accompanyingperson"
          />
        </b-form-group>
</b-col>
      </b-row>
    </b-form>

      </b-tab>

     <!-- Tab: Information -->
     <b-tab>
       <template #title>
         <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
         <span class="d-none d-sm-inline"> {{$t('MoreInfo')}}</span>
       </template>
       <b-row>
        <b-col cols="12" md="6">
       <p class="text-align-left">{{ $t("HospitalImages") }}</p>
       <b-card>
        <input
                type="file"
                ref="uploadImgInput"
                @change="AttachmentUploaded"
                accept="image/*"
                @on-success="successUpload"
              />
             <b-row>
              <b-col cols="12" md="3"
                v-for="item in hospital.HospitalAttachments"
                :key="item.ID"
              >
              <div >
                  <div style="position: absolute">
                    <feather-icon
                      icon="XIcon"
                      style="color: red"
                      svgClasses="w-10 h-10 hover:text-primary stroke-current"
                      @click.stop="removeImage(item)"
                    />
                  </div>
                  <div>
                    <img
                      style="border-radius: 10px; opacity: 1"
                      class="m-1"
                      :src="baseURL + item.AttachPath"
                      width="150"
                      height="150"
                      alt=""
                    />
                  </div>
                </div>
              </b-col>
             </b-row>
        <!-- <div id="data-list-list-view" class="data-list-container">
          <vs-table
            ref="table"
            :data="hospital.HospitalAttachments"
          >

            <template slot="thead">
              <vs-th >{{ $t("Image") }}</vs-th>
              <vs-th>{{ $t("Actions") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody :data="item" :key="indextr" v-for="(item, indextr) in data">
                <vs-tr class="cursor-pointer hover:text-primary">
                  <vs-td>
                    <img
                      style="border-radius: 10px; opacity: 1"
                      class="p-1"
                      :src="baseURL + item.AttachPath"
                      width="100"
                      height="100"
                      alt=""
                    />
                  </vs-td>
                  <vs-td>
                    <feather-icon
                      icon="XIcon"
                      style="color: red"
                      svgClasses="w-10 h-10 hover:text-primary stroke-current"
                      @click.stop="removeImage(item)"
                    />
                  </vs-td>
                 </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div> -->
      </b-card>
    </b-col>
      <b-col cols="12" md="6">
        <p class="text-align-left">{{ $t("Facilities") }}</p>
        <div id="data-list-list-view" class="data-list-container">
      <vs-table
            ref="table"
            :data="facilities"
          >

            <template slot="thead">
              <vs-th >{{ $t("Name") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody :data="item" :key="indextr" v-for="(item, indextr) in data">
                <vs-tr class="cursor-pointer hover:text-primary">
                  <vs-td>
                    <img
                    style="width: 50px; height: 20px"
                    alt=""
                    :src="baseURL + item.ImagePath"
                  />  {{$t(item.Name)}}
                  </vs-td>
                  <vs-td>
                    <b-form-checkbox
          v-model="item.IsChecked"
            :name="item.Name"
          />
                  </vs-td>
                 </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
      </b-col>
       </b-row>
      </b-tab>

   </b-tabs>
      <!-- Action Buttons -->
      <b-button
     variant="primary"
     class="mb-1 mb-sm-0 mr-0 mr-sm-1"
     @click="UpdateHospital()"
   >
     {{$t('SaveChanges')}}
   </b-button>
   <b-button
   v-if="$store.state.AppActiveUser.Hospital"
   type="border" color="danger"
     class="mb-1 mb-sm-0 mr-0 mr-sm-1"
     @click="$router.go(-1)"
   >
     {{$t('back')}}
   </b-button>
 </component>
 
</template>
<script>
import DataViewSidebar from "../medicalWebinar/DataViewSidebar.vue";
import moduleCity from "@/store/settings/city/moduleCity.js";
import moduleRegion from "@/store/settings/region/moduleRegion.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleHospitalGroup from "@/store/settings/HospitalGroup/moduleHospitalGroup.js";
import moduleFacility from "@/store/settings/facility/moduleFacility.js";
import moduleHospital from "@/store/hospital/moduleHospital.js";
import axios from "@/axios.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import VuePhoneNumberInput from 'vue-phone-number-input';
import { BAlert,  BLink, BTab, BTabs , BAvatar, BButton, BCard, BCardHeader, BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable } from "bootstrap-vue";

export default {
  components: {
    DataViewSidebar,
    quillEditor,
    VuePhoneNumberInput,BAlert, BLink, BTab, BTabs, BAvatar, BButton, BCard, BCardHeader, BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable 

  },
  data() {
    return {
      phonevalue:'',
       editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'link', 'blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'font': [] }],
          ],
        },
        placeholder: 'Message'
      },
      hospitalcountryID:0,
      EnglishLan: false,
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      hospital: {
        HospitalAttachments: [],
        HospitalSpecialties: [],
        HospitalFacilities: [],
        ImagePath: "",
        hospital:{}
      },
      options: [],
      radios1: "luis",
       defaultPhoneData:{
        countryCode : ""
      }
    };
  },

  methods: {
  //   acceptNumber(value) {
  //     debugger;
  //  //   this.phonevalue = this.hospital.Phone ;
  // // var x = this.hospital.Phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  //        this.phonevalue = value.e164;
  //        this.hospital.CountryID = this.countries.find((x) => x.IsoCode === value.countryCode).ID
  //   },

  acceptNumber(value) {
      debugger;
      if(this.hospital.CountryID !=null || this.hospitalcountryID !=0)
      {
      var country = this.countries.find((x) => (x.ID === this.hospital.CountryID ||x.ID=== this.hospitalcountryID));
      this.defaultPhoneData.countryCode =country.IsoCode;
      value.countryCode=this.defaultPhoneData.countryCode;
      this.phonevalue = value.e164;
      this.hospital.phone=this.phonevalue;
      }
      else
       {
         this.phonevalue = value.e164;
         this.defaultPhoneData.countryCode;
         }
       //  this.hospital.CountryID = this.countries.find((x) => x.IsoCode === value.countryCode).ID
    },
    removeImage(item) {
      var index = this.hospital.HospitalAttachments.findIndex(
        (x) => x.AttachPath == item.AttachPath
      );
      this.hospital.HospitalAttachments.splice(index, 1);
      if (item.ID > 0) this.$store.dispatch("HospitalList/DeleteAttachmenByID", item.ID);
    },
    UpdateHospital() {
      debugger;
      this.hospital.Phone = this.phonevalue;
      this.hospital.HospitalFacilities = this.facilities
        .filter((obj) => obj.IsChecked)
        .map((b) => {
          var h = {};
          h.FacilityID = b.ID;
          return h;
        });

      this.$store
        .dispatch("HospitalList/UpdateHospital", this.hospital)
        .then((res) => {
          if (
            this.$store.state.AppActiveUser.Hospital != null &&
            this.$store.state.AppActiveUser.Hospital.ID > 0
          ) {
            window.showSuccess();
            this.$router.go(-1);
          } else {
            this.$vs.notify({
              color: "success",
              title: "Successfully",
              text:
                "please wait till you confirm your account, you  will notify by mail",
            });
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    getRegionByCountryId(CountryId) {
      this.$store.dispatch("RegionList/GetAllRegionsByCountryID", CountryId).then(() => {
        if (this.hospital.RegionID > 0) {
          this.getCityByRegionId(this.hospital.RegionID);
        }
      });
    },
    getCityByRegionId(RegionId) {
      this.$store.dispatch("CityList/GetAllCitiesByRegionID", RegionId);
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      debugger;
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data,{
          headers: {
              "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
          this.hospital.ImagePath = response.data;
        });
      }
    },
    AttachmentUploaded(input) {
      debugger;
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data,{
          headers: {
              "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
          var temp = {};
          temp.AttachPath = response.data;
          this.hospital.HospitalAttachments.push(temp);
        });
      }
    },
    uploadHospitalAttachment(event) {
      debugger;
      var temp = {};
      temp.AttachPath = event.target.responseText;
      this.hospital.HospitalAttachments.push(temp);
    },
  },
  computed: {
    isFormValid()
    {
          return  this.hospital.NameEN
           && this.hospital.HospitalInfoEN
           &&  this.hospital.Address
          &&  this.hospital.ContactPersonEmail
          &&  this.hospital.CountryID
          &&  this.hospital.CityID
           &&  this.hospital.RegionID
    },
    cities() {
      return this.$store.state.CityList.cities;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    regions() {
      debugger;
      return this.$store.state.RegionList.regions;
    },
    hospitalGroups() {
      return this.$store.state.HospitalGroupList.HospitalGroups;
    },
    facilities() {
      debugger;
      return this.$store.state.FacilityList.facilities;
    },
    filterHospitalAttachments() {
      return this.hospital.HospitalAttachments.filter((obj) => obj.ID > 0);
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" || localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleRegion.isRegistered) {
      this.$store.registerModule("RegionList", moduleRegion);
      moduleRegion.isRegistered = true;
    }
    if (!moduleCity.isRegistered) {
      this.$store.registerModule("CityList", moduleCity);
      moduleCity.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }

    if (!moduleHospitalGroup.isRegistered) {
      this.$store.registerModule("HospitalGroupList", moduleHospitalGroup);
      moduleHospitalGroup.isRegistered = true;
    }
    if (!moduleFacility.isRegistered) {
      this.$store.registerModule("FacilityList", moduleFacility);
      moduleFacility.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }

    this.$store.dispatch("FacilityList/GetAllFacilities");
    this.$store.dispatch("HospitalGroupList/GetAllHospitalGroups");
    this.$store.dispatch("CountryList/GetAllCountries");

    var id = this.$route.params.Id!=null&&this.$route.params.Id!=undefined&&this.$route.params.Id!=0?this.$route.params.Id:this.$store.state.AppActiveUser.Hospital.ID;

    if (id) {
      this.$store.dispatch("HospitalList/GetHospital", id).then((res) => {
        debugger;
        this.hospital = res.data.Data;
        this.phonevalue=res.data.Data.Phone;
        this.hospital.Phone=res.data.Data.Phone;
        this.hospitalcountryID=res.data.Data.CountryID;

        if (this.hospital.CountryID > 0) {
          this.getRegionByCountryId(this.hospital.CountryID);
        }
        if (this.hospital.HospitalFacilities != null) {
          this.hospital.HospitalFacilities.forEach((element) => {
            var index = this.facilities.findIndex((x) => x.ID == element.FacilityID);
            debugger;
            if (index >= 0) this.facilities[index].IsChecked = true;
          });
        }
      });
    }
  },
};
</script>


<style lang="scss">
.onlineDot {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}
.offlineDot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
#page-user-list {
  .user-list-filters {
    height: 10px;

    .vs__actions {
      position: absolute;
      right: 0;
      top: 20%;
      height: 100px;
      transform: translateY(-58%);
    }
  }
}

#instant-search-demo {
  .header {
    .filters-label {
      width: calc(260px + 2.4rem);
    }
  }

  #content-container {
    .vs-sidebar {
      position: relative;
      float: left;
    }
  }

  .search-input-right-aligned-icon {
    padding: 1rem 1.5rem;
  }

  .price-slider {
    max-width: 80%;
  }

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
    min-width: 50%;
  }

  .item-view-secondary-action-btn {
    min-width: 50%;
  }
}

.theme-dark {
  #instant-search-demo {
    #content-container {
      .vs-sidebar {
        background-color: #10163a;
      }
    }
  }
}

.autocomplete {
  position: relative;
  width: 100%;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  overflow: auto;
  width: 100%;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #4ab6a2;
  color: white;
}

@media (min-width: 992px) {
  .vs-sidebar-rounded {
    .vs-sidebar {
      border-radius: 0.5rem;
    }

    .vs-sidebar--items {
      border-radius: 0.5rem;
    }
  }
}

@media (max-width: 992px) {
  #content-container {
    .vs-sidebar {
      position: fixed !important;
      float: left !important;
    }
  }
}

.grid-view-item {
  // height: 450px;

  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: 0.35s;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }

  button.page-link {
    display: inline-block;
  }

  button.page-link {
    font-size: 20px;
    color: #29b3ed;
    font-weight: 500;
  }

  .offset {
    width: 500px !important;
    margin: 20px auto;
  }
}

#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
.vs-sidebar.vs-sidebar-parent {
  max-height: 1500px !important;
}
</style>


